import store from "@/store";
import wx from "weixin-js-sdk";

export function getUrlKey(name) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
}

export function isPC() {
  let isPC = false;
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    isPC = false;
  } else {
    isPC = true;
  }
  return isPC;
}

//格式化日期
export function formatDate(dateString) {
  const normalizedDateString = dateString.replace(/\//g, '-');
  const date = new Date(normalizedDateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return store.state.language === "zh-CN" ? `${month}月${day}日` : `${day} June`;
}

//获取时间戳
export function getTimestamp(dateString) {
  // 替换斜杠为短横线以确保兼容性
  const normalizedDateString = dateString.replace(/\//g, '-');
  const date = new Date(normalizedDateString);
  return date.getTime();
}

export function isMiniProgram() {
  let ua = window.navigator.userAgent.toLowerCase();
  let that = this;
  return new Promise(resolve => {
    if (ua.indexOf('micromessenger') !== -1) {
      //微信环境下
      wx.miniProgram.getEnv(function (res) {
        console.log(res.miniprogram);
        if (res.miniprogram) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    } else {
      resolve(false);
    }
  })

}

export function createGroups(list, groupSize) {
  let groups = [];

  for (let i = 0; i < list.length; i += groupSize) {
    let group = list.slice(i, i + groupSize);
    groups.push(group);
  }

  return groups;
}

export function getAnswerListData(name, list) {
  for (const listElement of list) {
    if (name === listElement.ansCode || name.toUpperCase() === listElement.ansCode) {
      return listElement.ansText
    }
  }
}