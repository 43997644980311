<template>
  <div id="app">
    <topMenu v-if="!isMini"></topMenu>
    <router-view/>
    <iscFooter v-if="!isMini"></iscFooter>
  </div>
</template>
<script>
import {isMiniProgram} from "@/utils";

export default {
  data() {
    return {
      isMini: true
    }
  },
  created() {
    isMiniProgram().then(isMini => {
      this.isMini = isMini
    }).catch(err => {
      this.isMini = err
    })
  },
}
</script>


<style lang="less">
#app {
  background: #F7F9FA;
}
</style>
