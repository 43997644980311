<template>
  <div :class="isPC() ? 'pc' : 'mobile'" class="container">
    <el-carousel :height="isPC() ? '590px' : (language === 0 ? '266px':'325px')" arrow="never">
      <el-carousel-item v-for="(item,index) in list" :key="index">
        <div v-if="index === 0" :class="language === 0 ?'zh':'en'" :style="{backgroundImage: `url(${formatImg(item.url)})`}" class="banner banner1">
          <div class="box1">{{ $t('carouselContent1') }}</div>
          <div class="box2">{{ $t('carouselContent2') }}</div>
          <div v-if="isPC()" class="box3">{{ $t('carouselContent3PC') }}</div>
          <div v-else class="box3" v-html="$t('carouselContent3Mobile')"></div>
          <div class="box4">{{ $t('carouselContent4') }} <span class="line">|</span> {{ $t('carouselContent5') }}</div>
          <div class="box5"><span class="registerBtn" @click="goRegister">{{ $t('registerNow') }}</span></div>
        </div>
        <div v-else :style="{backgroundImage: `url(${formatImg(item.url)})`}" class="banner">
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import store from "@/store";
import {isPC} from "@/utils";

export default {
  name: '',
  data() {
    return {
      list: [],
      language: this.$store.getters.language,
    }
  },
  created() {
    console.log(this.language)
  },
  mounted() {
    this.list = this.$t('indexBanner')
  },
  methods: {
    isPC,
    goRegister() {
      let url = [
        'https://reg.shanghaisummit.cn/reg/SHS24H5/#/?language=zh-CN',
        'https://reg.shanghaisummit.cn/reg/SHS24H5/#/?language=en-US'
      ]
      window.open(url[store.getters.language])
    },
    formatImg(url) {
      if (isPC()) {
        return url
      } else {
        return (url + '?x-oss-process=image/resize,w_738,m_fill')
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-carousel__indicators {
  bottom: 65px;

  .el-carousel__button {
    width: 56px;
    height: 8px;
    border-radius: 4px;
  }
}

.banner {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.banner1 {

  > div {
    width: 1308px;
    margin: 0 auto;
    color: #FFFFFF;
    font-weight: 600;

  }

  .box1 {
    height: 70px;
    line-height: 70px;
    font-size: 50px;
    padding: 65px 0 5px 0;
  }

  .box2 {
    font-size: 31px;
    margin-bottom: 35px;
  }

  .box3 {
    font-size: 70px;
    margin-bottom: 53px;
  }

  .box4 {
    font-size: 33px;
    margin-bottom: 17px;

    .line {
      margin: 0 50px;
    }
  }

  .registerBtn {
    display: inline-block;
    width: 143px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    background: #DA1969;
    border-radius: 8px;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
  }
}

.en {
  .box3 {
    font-size: 42px;
  }
}

.mobile {

  .banner1 {
    > div {
      width: 100%;
      margin: 0 26px;
    }

    .box1 {
      height: 22px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      padding-top: 17px;
    }

    .box2 {
      height: 14px;
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
    }

    .box3 {
      height: 78px;
      font-size: 27px;
      line-height: 26px;
      margin-top: 19px;
    }

    .box4 {
      height: 17px;
      font-size: 12px;
      line-height: 17px;
      margin-top: 11px;

      .line {
        margin: 0 20px;
      }
    }

    .registerBtn {
      width: 79px;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      margin-top: 10px;
    }
  }

  .en {
    .box3 {
      font-size: 24px;
      height: auto;
    }

    .box4 {
      height: auto;
    }

    .registerBtn{
      font-size: 10px;
    }
  }

  /deep/ .el-carousel__indicators {
    bottom: 20px;

    .el-carousel__button {
      width: 26px;
      height: 4px;
      border-radius: 4px;
    }
  }
}


</style>
