import Vue from "vue";
import VueI18n from "vue-i18n";
import ElementLocale from "element-ui/lib/locale";
import elementEnLocale from "element-ui/lib/locale/lang/en";
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN";
import store from "@/store";
// import zh from "./zh";
// import en from "./en";
Vue.use(VueI18n);
const i18n = new VueI18n({
  // legacy: false, // Composition API 模式
  globalInjection: true, // 全局注册 $t方法
  locale: store.state.language,
  messages: {
    "en-US": {
      ...en,
      ...elementEnLocale,
    },
    "zh-CN": {
      ...zh,
      ...elementZhLocale,
    },
  },
});
ElementLocale.i18n((key, value) => i18n.t(key, value));
export default i18n;
