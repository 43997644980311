<template>
  <div :class="{pc: isPC(), mobile: !isPC(),en: language === 1}" class="menuBox">
    <el-image :src="$t('menuLogo')" @click="goHome"></el-image>
    <el-menu v-if="isPC()" :default-active="activeIndex" class="isc-menu" mode="horizontal" @select="handleSelect">
      <el-menu-item v-for="route in handleRoutes" :key="route.path" :index="route.path">{{ route.name }}</el-menu-item>
    </el-menu>
    <div class="rightRegister">
      <span class="changeInner" @click="changeLanguage">{{ language === 0 ? 'EN' : '中' }}</span>
      <span class="registerBtn" @click="goRegister">{{ $t('registerNow') }}</span>
      <div v-if="!isPC()" class="square" @click="drawer = true">
        <div></div>
      </div>
      <el-drawer
        :modal-append-to-body="false"
        :size="150"
        :visible.sync="drawer"
        :with-header="false">
        <el-menu :default-active="activeIndex" class="isc-menu" @select="handleSelect">
          <el-menu-item v-for="route in handleRoutes" :key="route.path" :index="route.path">{{ route.name }}</el-menu-item>
        </el-menu>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {isPC} from "@/utils";
import {EventBus} from "@/utils/eventBus";

export default {
  data() {
    return {
      drawer: false,
      language: 0,
      activeIndex: '/zzz',
      handleRoutes: [
        {
          path: '/home',
          name: this.$t('menuList.index')
        },
        {
          path: '/aboutConference',
          name: this.$t('menuList.about')
        },
        {
          path: '/schedules',
          name: this.$t('menuList.schedules')
        },
        {
          path: '/speakerList',
          name: this.$t('menuList.guestList')
        },
        {
          path: '/listOfDelegates',
          name: this.$t('menuList.delegates')
        },
        {
          path: 'https://m.alltuu.com/album/1334347563',
          name: this.$t('menuList.picAndLive')
        },
        {
          path: '/hotel',
          name: this.$t('menuList.hotel')
        },
        {
          path: '/arrive',
          name: this.$t('menuList.arrive')
        },
        {
          path: '/partner',
          name: this.$t('menuList.partner')
        },
      ]
    }
  },
  created() {
    this.language = store.getters.language
    setTimeout(() => {
      this.activeIndex = this.$router.currentRoute.fullPath
    }, 300)

    EventBus.$on('handleJump', (data) => {
      this.changeActiveIndex(data)
    });
  },
  methods: {
    isPC,
    handleSelect(key, keyPath) {
      if (key.startsWith('http') || key.startsWith('//')) {
        window.open(key, '_blank')
      } else if (this.$router.currentRoute.fullPath !== key) {
        this.$router.push({path: key})

        !isPC() && this.drawer && (this.drawer = false)
      }
    },
    changeLanguage() {
      this.language === 0 ? this.language = 1 : this.language = 0
      store.commit('SET_LANGUAGE', this.language === 0 ? 'zh-CN' : 'en-US')
      location.reload()
    },
    goRegister() {
      let url = [
        'https://reg.shanghaisummit.cn/reg/SHS24H5/#/?language=zh-CN',
        'https://reg.shanghaisummit.cn/reg/SHS24H5/#/?language=en-US'
      ]
      window.open(url[this.language])
    },
    changeActiveIndex(key, keyPath) {
      this.activeIndex = key
    },
    goHome() {
      if (this.$router.currentRoute.fullPath !== '/home') {
        this.$router.push({path: '/home'})
      }
    }
  }
}

</script>

<style lang="less" scoped>

.menuBox {
  height: 90px;
  line-height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F7F9FA;
  position: sticky;
  top: 0;
  z-index: 2000;

  .el-image {
    width: 227px;
    display: flex;
    align-items: center;
    margin-left: 50px;
    cursor: pointer;
  }

  .isc-menu {
    border: none;
    display: inline-block;
    min-width: 1080px;
    background: #F7F9FA;

    .el-menu-item {
      min-width: 100px;
      text-align: center;
      font-size: 16px;
      color: #48484A;
      padding: 0;
      margin: 0 10px;

      &:hover {
        background: #F7F9FA !important;
        font-weight: bolder;
        transition: .8s all;
      }

      &:focus {
        background: #F7F9FA !important;
      }

    }

  }

  .rightRegister {
    min-width: 200px;
    margin-right: 30px;

    .changeInner {
      display: inline-block;
      width: 45px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      border-radius: 8px;
      border: 1px solid #48484A;
      font-size: 16px;
      color: #48484A;
      margin-right: 10px;
      cursor: pointer;
    }

    .registerBtn {
      display: inline-block;
      width: 143px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      background: #DA1969;
      border-radius: 8px;
      font-size: 16px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}

.mobile {
  height: 60px;
  line-height: 60px;

  .el-image {
    margin-left: 10px;
  }

  .rightRegister {
    display: flex;
    justify-content: flex-end;

    .changeInner {
      width: 36px;
      height: 36px;
      line-height: 36px;
    }

    .registerBtn {
      width: 75px;
      height: 36px;
      line-height: 36px;
      font-size: 13px;
    }

    .square {
      width: 18px;
      height: 18px;
      position: relative;
      margin: 10px 10px 10px 21px;
    }

    .square::before,
    .square::after,
    .square div {
      content: '';
      position: absolute;
      left: 0;
      height: 2px;
      background-color: #575B66;
      width: 18px;
    }

    .square::before {
      top: 0;
    }

    .square div {
      top: 5px;
    }

    .square::after {
      top: 10px;
    }

    .isc-menu {
      min-width: auto;
      margin-top: 30px;
      background-color: #fff;
      width: 150px;

      .el-menu-item {
        margin: 0;
      }
    }
  }

}

.mobile.en {
  .registerBtn {
    //line-height: 17px !important;
  }
}


</style>