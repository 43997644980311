import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";
import {getUrlKey} from "@/utils";

Vue.use(Vuex);

export default new Vuex.Store({
  // plugins: [
  //   createPersistedState({
  //     storage: window.localStorage,
  //     reducer: (state) => {
  //       return {
  //         // 默认是全部缓存，在这里可以设置需要缓存的状态
  //         token: state.token,
  //         user: state.user,
  //       };
  //     },
  //   }),
  // ],
  state: {
    language: getUrlKey('language') || localStorage.getItem("language") || "zh-CN",
  },
  getters: {
    language(state) {
      return state.language === "zh-CN" ? 0 : 1;
    },
  },
  mutations: {
    SET_LANGUAGE(state, item) {
      localStorage.setItem("language", item);
      state.language = item;
    },
  },
  actions: {},
  modules: {},
});
