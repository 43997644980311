import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from "@/i18n";
import {EventBus} from "@/utils/eventBus";

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue'),
  },
  {
    path: '/aboutConference',
    name: 'aboutConference',
    component: () => import('../views/aboutConference.vue'),
  },
  {
    path: '/schedules',
    name: 'schedules',
    component: () => import('../views/schedules.vue'),
  },

  {
    path: '/hotel',
    name: 'hotel',
    component: () => import('../views/hotel.vue'),
  },
  {
    path: '/partner',
    name: 'partner',
    component: () => import('../views/partner.vue'),
  },
  {
    path: '/arrive',
    name: 'arrive',
    component: () => import('../views/arrive.vue'),
  },
  {
    path: '/speakerList',
    name: 'speakerList',
    component: () => import('../views/speakerList.vue'),
  },
  {
    path: '/newestList',
    name: 'newestList',
    component: () => import('../views/newestList.vue'),
  },
  {
    path: '/newestDetail',
    name: 'newestDetail',
    component: () => import('../views/newestDetail.vue'),
  },
  {
    path: '/listOfDelegates',
    name: 'listOfDelegates',
    component: () => import('../views/listOfDelegates.vue'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // const isClosed = dayjs().isAfter(dayjs(config.closedDate));
  // if (isClosed && to.name !== 'closed') {
  //   next({name: 'closed'})
  // }

  document.documentElement.scrollTop = 0;
  document.title = i18n.t('webTitle')
  // if (to.meta.ignore) {


  EventBus.$emit('handleJump', to.fullPath)

  next()
  // } else {
  //   if (store.state.token && !store.state.isTokenExpired) {
  //     GetInfo().then(res => {
  //       // console.log(res)
  //       const {data} = res
  //       if (data) {
  //
  //         const {info} = data
  //         store.commit('SET_USER', info)
  //         const {responses} = info
  //
  //         console.log('responses', responses);
  //         if (to.name === 'registerIndex') {
  //           isMiniProgram().then(isMini => {
  //             next({name: isMini ? 'baseInfo' : (responses.includes('P24') ? 'confirm' : 'baseInfo')})
  //           })
  //         }
  //         if (to.name === 'pay') {
  //           if (responses.includes('PAID24') || responses.includes('FOC24')) {
  //             next({name: 'confirm'})
  //           }
  //           if (responses.includes('BANK24') && responses.includes('NP24')) {
  //             next()
  //           }
  //         }
  //         if (to.name === 'confirm' && responses.includes('NP24') && !responses.includes('BANK24')) {
  //           next({name: 'pay'})
  //         } else {
  //           next()
  //         }
  //       }
  //
  //     })
  //   } else {
  //     next()
  //   }
  // }
});

export default router
