<template>
  <div :class="isPC() ? 'pc' : 'mobile'" class="container">
    <div class="title">
      <div class="inner">
        {{ $t('newestTitle') }}
      </div>
      <div class="rightImg">
        <el-image
          src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/%E5%BD%A2%E7%8A%B6%E7%BB%93%E5%90%88%E5%A4%87%E4%BB%BD%402x.png"
          @click="handleJump('newestList')"></el-image>
      </div>
    </div>

    <div class="content">
      <ul>
        <li v-for="(item,index) in list" :key="index" :class="isPC() ? { 'active': isHovering[`isHovering${index+1}`] } : 'active'"
            :style="backgroundImageStyle(item.C_LOGO)"
            @click="handleClick(item)"
            @mouseenter="mouseenter(`isHovering${index+1}`)"
            @mouseleave="mouseleave(`isHovering${index+1}`)">
          <a>
            <div class="innerBox">
              <div class="innerTitle">{{ item.C_TITLE }}</div>
            </div>
            <div class="hoverBox">
              <h3>{{ item.C_TITLE }}</h3>
              <div v-if="isPC()" class="detail">{{ item.C_ABSTRACT }}</div>
              <div class="time">{{ item.C_DATETIME }}<i class="el-icon-right"></i></div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {GetArticleList} from "@/api";
import {isPC} from "@/utils";

export default {
  name: '',
  data() {
    return {
      list: [],
      isHovering: {
        isHovering1: true,
        isHovering2: false,
        isHovering3: false,
        isHovering4: false,
      },
      language: this.$store.getters.language,
    }
  },
  computed: {},
  created() {
    this.init()
  },
  methods: {
    isPC,
    init() {
      let id = this.language === 0 ? 54 : 55
      GetArticleList(id).then(res => {
        if (res.list.length) {
          for (let i = 0; i < 4; i++) {
            this.list.push(res.list[i])
          }
        }
      })
    },
    mouseenter(item) {
      this.isHovering.isHovering1 = false
      this.isHovering.isHovering2 = false
      this.isHovering.isHovering3 = false
      this.isHovering.isHovering4 = false
      this.isHovering[item] = true
    },
    mouseleave(item) {

    },
    backgroundImageStyle(url) {
      return {
        background: `url(${url}?x-oss-process=image/resize,w_738,h_475,m_fill) center no-repeat`
      };
    },
    handleJump(url) {
      this.$router.push({path: url})
    },
    handleClick(item) {
      if (item.C_EXTERNAL_LINK) {
        window.open(item.C_EXTERNAL_LINK)
      } else {
        this.$router.push({
          path: '/newestDetail',
          query: {
            id: item.N_ARTICLE_ID
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  margin-top: 59px;
  margin-bottom: 59px;
  background-color: #fff;

  .title {
    font-weight: 600;
    font-size: 26px;
    color: #1077E1;
    position: relative;
    padding-top: 32px;
    display: flex;
    justify-content: center;
    max-width: 1700px;;
    margin: 0 30px 36px;

    &:after {
      display: none;
    }

    .inner {
      position: relative;
    }

    .rightImg {
      position: absolute;
      right: 0;
      top: 27px;

      .el-image {
        height: 36px;
        width: 36px;
        cursor: pointer;
      }

    }

  }

  .content {
    max-width: 1700px;
    padding: 0 30px;
    overflow: hidden;
    margin: 0 auto;

    ul {
      width: 101%;

      li {
        float: left;
        height: 475px;
        width: 17%;
        position: relative;
        transition: all .4s;
        -webkit-transition: all .4s;
        margin-right: 1%;
        //background-position: center !important;
        //background-size: 100% 100% !important;
        //background-repeat: no-repeat !important;

        a {
          display: block;
          width: 100%;
          height: 100%;
          cursor: pointer;

          &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            //background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 75%);
            //background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 75%);
            content: '';
            -webkit-transform: translate3d(0, 70%, 0);
            transform: translate3d(0, 70%, 0);
            -webkit-transition: opacity 0.4s ease-in, -webkit-transform 0.4s;
            transition: opacity 0.4s ease-in, transform 0.4s;
          }
        }

        .innerBox {
          padding: 0 7%;
          width: 86%;
          position: absolute;
          bottom: 25px;
          left: 0;
          opacity: 1;
          transition: all .4s;
          -webkit-transition: all .4s;

          .innerTitle {
            font-size: 18px;
            color: #fff;
            font-weight: bold;
            line-height: 1.4;
            margin-bottom: 3px;
          }

        }

        .hoverBox {
          display: block;
          position: absolute;
          bottom: 20px;
          left: 0;
          width: 86%;
          padding: 0 7%;
          opacity: 0;
          transition: all .4s;
          -webkit-transition: all .4s;

          h3 {
            font-size: 22px;
            color: #ffffff;
            line-height: 1.4;
            margin-bottom: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .detail {
            font-size: 16px;
            line-height: 1.8;
            color: #f4f3f5;
            padding-right: 10%;
            margin-bottom: 25px;
            height: 55px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
          }

          .time {
            font-size: 16px;
            height: 41px;
            line-height: 41px;
            color: #f6f5f7;
            position: relative;

            i {
              display: block;
              width: 41px;
              height: 41px;
              position: absolute;
              right: 0;
              top: 0;
              transition: all .4s;
              -webkit-transition: all .4s;
            }
          }

        }
      }

      .active {
        width: 43%;

        a {
          &:before {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #3142D4 75%);
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #18216c 100%)
          }
        }

        .innerBox {
          opacity: 0;
        }

        .hoverBox {
          opacity: 1;
        }
      }
    }

  }
}

.mobile {
  margin-top: 0;

  //.title {
  //  .rightImg {
  //    right: 20px;
  //  }
  //}

  .content {
    padding: 0 20px;

    ul {
      li {
        width: 100%;
        height: 190px;
        margin-bottom: 11px;
        background-size: cover !important;

        .hoverBox {
          h3 {
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 7px;
          }

          .time {
            font-size: 14px;
            height: 20px;
            line-height: 20px;

            i {
              top: 3px;
            }
          }
        }
      }

      .active {
        width: 100%;
      }
    }

  }

  //.container .content ul li .hoverBox h3[data-v-52b761e2]
}

</style>
