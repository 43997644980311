<template>
  <div :class="isPC() ? 'pc' : 'mobile'" class="container">
    <div class="title">
      <div class="inner">
        {{ $t('organizationTitle') }}
      </div>
    </div>

    <div class="content">

      <div class="innerBox">
        <div class="innerTitle">
          <el-image src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/%E7%BC%96%E7%BB%84%402x(1).png"></el-image>
          {{ $t('organizationTitle1') }}
        </div>
        <div class="innerImgBox">
          <div class="innerImg">
            <el-image :style="isPC() ? 'width: 212px;' : 'width: 111px;'" fit="cover"
                      src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/24971714269032_.pic%402x.png"></el-image>
          </div>
          <div class="innerImg">
            <el-image :style="isPC() ? 'width: 268px;' : 'width: 131px;'" fit="cover"
                      src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/%E7%BC%96%E7%BB%84%402x.png"></el-image>
          </div>
          <div v-if="isPC()" class="innerImg">
            <el-image fit="cover" src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/28051715583433_.pic%402x.png"
                      style="width: 178px;"></el-image>
          </div>
        </div>
        <div v-if="!isPC()" class="innerImgBox">
          <div class="innerImg">
            <el-image fit="cover" src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/28051715583433_.pic%402x.png"
                      style="width: 92px"></el-image>
          </div>
        </div>
      </div>

      <div class="innerBox">
        <div class="innerTitle">
          <el-image src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/%E7%BC%96%E7%BB%84%402x(1).png"></el-image>
          {{ $t('organizationTitle2') }}
        </div>
        <div class="innerImgBox">
          <div class="innerImg">
            <el-image :style="isPC() ? 'width: 230px;' : 'width: 121px;'" fit="cover"
                      src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/%E7%BC%96%E7%BB%84%402x(2).png"></el-image>
          </div>
        </div>
      </div>

      <div class="innerBox">
        <div class="innerTitle">
          <el-image src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/%E7%BC%96%E7%BB%84%402x(1).png"></el-image>
          {{ $t('organizationTitle5') }}
        </div>
        <div class="innerImgBox">
          <div class="innerImg">
            <el-image :style="isPC() ? 'width: 236px;' : 'width: 125px;'" fit="cover"
                      src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/24981714269085_.pic%402x.png"></el-image>
          </div>
        </div>
      </div>

      <div class="innerBox">
        <div class="innerTitle">
          <el-image src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/%E7%BC%96%E7%BB%84%402x(1).png"></el-image>
          {{ $t('organizationTitle3') }}
        </div>
        <div class="innerImgBox">
          <div class="innerImg">
            <el-image :style="isPC() ? 'width: 197px;' : 'width: 116px;'" fit="cover"
                      src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/25001714269268_.pic%402x.png"></el-image>
          </div>
        </div>
      </div>

      <div class="innerBox">
        <div class="innerTitle">
          <el-image src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/%E7%BC%96%E7%BB%84%402x(1).png"></el-image>
          {{ $t('organizationTitle4') }}
        </div>
        <div class="innerImgBox">
          <div class="innerImg">
            <el-image :style="isPC() ? 'width: 205px;' : 'width: 125px;'" fit="cover"
                      src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/ufi%402x.png"></el-image>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import {isPC} from "@/utils";

export default {
  name: '',
  methods: {isPC},
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.container {

  .title {
    font-weight: 600;
    font-size: 26px;
    color: #1077E1;
    margin-bottom: 36px;
    position: relative;
    padding-top: 32px;
    display: flex;
    justify-content: center;

    &:after {
      display: none;
    }

    .inner {
      position: relative;
    }
  }

  .content {
    max-width: 1705px;
    margin: 0 auto;
    padding: 0 30px;

    .innerBox {
      display: inline-grid;
      margin-right: 10px;
      margin-bottom: 20px;

      .innerTitle {
        //width: 200px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        .el-image {
          width: 19px;
          height: 19px;
          margin-right: 6px;
        }
      }

      .innerImgBox {
        display: flex;

        .innerImg {
          width: 335px;
          height: 140px;
          background: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }


    }

  }
}

.mobile {
  .content {
    padding: 0 20px;

    .innerBox {
      margin-right: 0;
      width: 100%;

      .innerTitle {
        margin-bottom: 0;
      }

      .innerImgBox {
        width: 100%;

        .innerImg {
          width: 50%;
          height: 70px;
          padding: 0 5px;
          margin-top: 10px;
        }
      }
    }
  }
}

</style>
