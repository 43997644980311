<template>
  <div :class="isPC() ? 'pc' : 'mobile'" class="container">
    <div class="title">
      <div class="inner">
        {{ $t('speakerTitle') }}
      </div>
      <div class="rightImg">
        <el-image
          src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/%E5%BD%A2%E7%8A%B6%E7%BB%93%E5%90%88%E5%A4%87%E4%BB%BD%402x.png"
          @click="handleJump('speakerList')"></el-image>
      </div>
    </div>

    <el-carousel :autoplay="false" :height="isPC() ? (language === 0 ? '500px':'560px') : (language === 0 ? '380px':'500px')" arrow="always"
                 indicator-position="none">
      <el-carousel-item v-for="(item , index) in list" :key="index">
        <div class="content">
          <div v-for="innerItem in item" :key="innerItem.C_LOGO" class="inner" @click="openDialog(innerItem)">
            <div class="imgBox">
              <el-image :src="innerItem.C_LOGO"></el-image>
            </div>
            <div class="name">{{ innerItem.C_TITLE }}</div>
            <div class="position">{{ innerItem.C_ABSTRACT }}</div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>


    <el-dialog
      :visible.sync="dialogVisible"
      :width="isPC() ? '1300px' : '80%'">
      <el-row>
        <el-col :class="!dialogContent.C_CONTENT ? 'noIntroduce' : ''" :span="isPC() ? (dialogContent.C_CONTENT ? 4: 24) : 24">
          <div class="img">
            <el-image :src="dialogContent.C_LOGO"></el-image>
          </div>
          <div class="name">{{ dialogContent.C_TITLE }}</div>
          <div class="position">{{ dialogContent.C_ABSTRACT }}</div>
        </el-col>
        <el-col v-if="dialogContent.C_CONTENT" :span="isPC() ? 20 : 24">
          <div class="introduce">
            <div class="introduceTitle">{{ $t('speakerListRecord') }}</div>
            <div class="introduceContent" v-html="dialogContent.C_CONTENT"></div>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>

import {createGroups, isPC} from "@/utils";
import {GetArticleList} from "@/api";

export default {
  name: '',
  data() {
    return {
      list: [],
      dialogVisible: false,
      dialogContent: '',
      language: this.$store.getters.language,
    }
  },
  // mounted() {
  //   if (isPC()) {
  //     this.list = this.$t('speakerList')
  //   } else {
  //     for (let i = 0; i < 2; i++) {
  //       this.list.push(this.$t('speakerList')[i])
  //     }
  //   }
  // },
  created() {
    this.init()
  },
  methods: {
    isPC,
    init() {
      let id = this.language === 0 ? 56 : 60
      GetArticleList(id).then(res => {
        this.list = createGroups(res.list, isPC() ? 5 : 2);
        console.log(this.list)
        console.log(this.list[0]);
      })
    },
    openDialog(item) {
      console.log(item)
      this.dialogContent = item
      this.dialogVisible = true
    },
    handleJump(url) {
      this.$router.push({path: url})
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  background: #3142D4;

  .title {
    font-weight: 600;
    font-size: 26px;
    color: #fff;
    position: relative;
    padding-top: 32px;
    display: flex;
    justify-content: center;
    max-width: 1700px;
    margin: 0 30px;

    &:after {
      display: none;
    }

    .inner {
      position: relative;
    }

    .rightImg {
      position: absolute;
      right: 0;
      top: 27px;

      .el-image {
        height: 36px;
        width: 36px;
        cursor: pointer;
      }

    }

  }

  .content {
    max-width: 1700px;;
    margin: 0 auto;
    padding: 46px 30px;
    color: #FFFFFF;
    display: flex;

    .inner {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      .imgBox {
        height: 240px;
        width: 240px;
        border-radius: 120px;
        border-bottom-left-radius: 0;
        overflow: hidden;
      }

      .name {
        font-size: 26px;
        margin-top: 20px;
        margin-bottom: 10px;
        font-weight: bold;
        width: 240px;
        text-align: left;
      }

      .position {
        font-size: 16px;
        width: 240px;
        text-align: left;
        line-height: 22px;
        white-space: pre-line;

      }
    }


  }

  /deep/ .el-dialog {
    border-radius: 8px;

    .el-dialog__header {
      padding: 0;

      .el-dialog__headerbtn {
        .el-dialog__close {
          color: #1077E1;
          font-weight: bolder;
        }
      }
    }


    .el-dialog__body {
      padding: 16px 27px;

      .el-col {
        padding: 10px;
      }

      .name {
        margin-top: 21px;
        font-weight: 600;
        font-size: 26px;
        color: #48484A;
        line-height: 37px;
        position: relative;
        margin-bottom: 27px;
        word-break: break-word;

        &:after {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 0;
          width: 40px;
          height: 3px;
          background-color: #3142D4;
        }
      }

      .position {
        font-size: 16px;
        color: #48484A;
        line-height: 27px;
        word-break: break-word;
        white-space: pre-line;

      }

      .introduce {

        .introduceTitle {
          font-weight: 600;
          font-size: 16px;
          color: #48484A;
          margin-bottom: 20px;
        }

        .introduceContent {
          font-size: 16px;
          color: #48484A;
          line-height: 32px;
          word-break: break-word;
        }
      }

      .noIntroduce {
        display: flex;
        flex-direction: column;
        align-items: center;

        > .img {
          width: 250px;
        }
      }
    }
  }


}

.mobile {
  margin-top: 0;
  //
  .title {
    font-size: 21px;
  }

  .content {
    padding: 46px 20px;

    .inner {
      &:first-child {
        padding-right: 20px;
      }

      &:last-child {
        padding-left: 10px;
      }

      .imgBox {
        height: 133px;
        width: 133px;
      }

      .name {
        width: auto;
        font-weight: 600;
        font-size: 18px;
      }

      .position {
        width: auto;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}


</style>
