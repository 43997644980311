<template>
  <div class="container">
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>

<style lang="less" scoped>
.container {

}
</style>
