import Vue from 'vue'
import router from './router'
import store from './store'
import '@/assets/css/base.css'

import ElementUI from 'element-ui';
import i18n from '@/i18n'

import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import {getUrlKey} from "@/utils";

Vue.use(ElementUI);

let language = getUrlKey('language')
language && store.commit('SET_LANGUAGE',language)
// 使用require.context自动化导入所有组件
const componentsContext = require.context('./components', true, /\.vue$/);

componentsContext.keys().forEach(componentPath => {
  const componentConfig = componentsContext(componentPath);
  const componentName = componentPath.split('/').pop().replace(/\.\w+$/, '');

  // 全局注册组件
  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  );
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
