import axios from "axios";
import store from "@/store";
import {Message} from "element-ui";

const loadingOption = {
  lock: true, text: "Loading", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)",
};
// let loadingInstance;
let language = store.state.language === "zh-CN" ? 0 : 1;
let urlList = ['/reg/CEOSTWesite/api', '/reg/CEOSTWesite/apien']
console.log(urlList[language])
// 创建axios实例
const service = axios.create({
  baseURL: window.baseURL || urlList[language], timeout: 30000, // 请求超时时间
});

// request 拦截
service.interceptors.request.use((config,) => {
  // store.state.token && (config.headers["Authorization"] = "Bearer " + store.state.token);
  // config.headers["Accept-Language"] = store.state.language;
  // config.url !== '/VipRegister/GetOrderStatus' && (loadingInstance = Loading.service(loadingOption))
  return config;
}, (error) => {
  return error;
});

// response 拦截
service.interceptors.response.use((response) => {
  // loadingInstance.close();
  if (response.data.state === 'success') {
    return response.data;
  } else {

    // Message({
    //   message: i18n.t(`Error.${response.data.code}`), type: "error",
    // });
  }
}, (error) => {
  // loadingInstance.close();
  Message({
    message: "Network error", type: "error",
  });
  return error;
});

export default service;
