<template>
  <div :class="{pc: isPC(), mobile: !isPC(),en: language === 1}" class="container">
    <div class="topBox">
      <div class="leftInner">
        <div class="title">
          {{ $t('introduceTitle') }}
        </div>
        <div class="content">
          {{ $t('introduceContent') }}
          <el-image src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/%E5%BD%A2%E7%8A%B6%E7%BB%93%E5%90%88%402x.png"
                    @click="handleJump('aboutConference')"></el-image>

        </div>
      </div>
      <div class="rightInner">
        <el-image
          src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/pexels-divinetechygirl-1181400%402x.png"></el-image>
      </div>
    </div>
    <div class="middleBox">{{ $t('threeTopicTitle') }}</div>
    <div class="bottomBox">
      <el-row :gutter="5">
        <el-col :span="isPC()?8 : 24">
          <div class="boxInner">
            <el-image src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/tiaozhan%402x.png"></el-image>
            <div class="innerTitle">{{ $t('topic1Title') }}</div>
            <div class="innerContent">
              {{ $t('topic1Content') }}
            </div>
          </div>
        </el-col>
        <el-col :span="isPC()?8 : 24">
          <div class="boxInner">
            <el-image src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/tiyanjihui%402x.png"></el-image>
            <div class="innerTitle">{{ $t('topic2Title') }}</div>
            <div class="innerContent">{{ $t('topic2Content') }}</div>
          </div>
        </el-col>
        <el-col :span="isPC()?8 : 24">
          <div class="boxInner">
            <el-image src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/chuangxin%402x.png"></el-image>
            <div class="innerTitle">{{ $t('topic3Title') }}</div>
            <div class="innerContent">{{ $t('topic3Content') }}</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {isPC} from "@/utils";

export default {
  name: '',
  data() {
    return {
      language: this.$store.getters.language,
    }
  },
  created() {
  },
  methods: {
    isPC,
    handleJump(url) {
      this.$router.push({path: url})
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  width: 1308px;
  margin: 0 auto;
  position: relative;
  top: -37px;
  z-index: 999;

  .topBox {
    height: 294px;
    overflow: hidden;

    .leftInner {
      width: 700px;
      padding: 74px 83px;
      display: inline-block;

      .title {
        font-weight: 600;
        font-size: 26px;
        color: #1077E1;
        margin-bottom: 36px;
        position: relative;
      }

      .content {
        font-size: 16px;
        color: #48484A;
        line-height: 28px;
        position: relative;

        .el-image {
          width: 17px;
          height: 13px;
          position: absolute;
          right: 15px;
          bottom: -10px;
          cursor: pointer;
        }
      }
    }

    .rightInner {
      float: right;

      .el-image {
        width: 442px;
        height: 294px;
      }
    }
  }

  .middleBox {
    height: 60px;
    line-height: 60px;
    background: #1077E1;
    color: #FFFFFF;
    text-align: center;
    font-size: 22px;
  }

  .bottomBox {
    background: #F7F9FA;

    .boxInner {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
      color: #48484A;
      background-color: #fff;
      height: 400px;

      .el-image {
        width: 75px;
        height: 75px;
        margin: 27px 0 32px;
      }

      .innerTitle {
        font-weight: bold;
        line-height: 28px;
        width: 300px;
        margin-bottom: 30px;
        text-align: center;
      }

      .innerContent {
        line-height: 28px;
        width: 300px;
      }

    }


  }
}

.mobile {
  width: 324px;
  top: -20px;

  .topBox {
    height: 330px;
    position: relative;

    .leftInner {
      width: 100%;
      padding: 31px 15px;
      box-sizing: border-box;

      .title {
        margin-bottom: 50px;
      }

      .content {
        font-size: 14px;
        color: #48484A;
        line-height: 28px;
      }
    }

    .rightInner {
      position: absolute;
      right: 0;
      top: 0;

      .el-image {
        width: 151px;
        height: 100px;
      }

    }
  }

  .bottomBox {
    .boxInner {
      margin-bottom: 5px;
      font-size: 14px;
      height: auto;
      padding-bottom: 20px;

      .innerContent {
        width: 290px;
      }
    }
  }

}

.en {
  .topBox {
    height: auto;
  }

  .bottomBox{
    .boxInner{
      min-height: 578px;
    }
  }
}

.mobile.en{
  .bottomBox{
    .boxInner{
      height: auto;
      padding-bottom: 20px;
    }
  }
}
</style>
