import request from "@/utils/request.js";
import request2 from "@/utils/request2.js";

export async function GetBanners(data, params) {
  return request({
    url: "/GetBanners",
    method: "post",
  });
}

export async function GetArticleList(id, params) {
  return request({
    url: "/GetArticleList?sujectid=" + id,
    method: "post",
    // data,
    // params,
  });
}

export async function GetArticleById(id, params) {
  return request({
    url: "/GetArticleById?id=" + id,
    method: "post",
    // data,
    // params,
  });
}

export async function GetDel24(data, params) {
  return request2({
    url: "/VipRegister/GetDel24",
    method: "post",
    // data,
    // params,
  });
}

